import { Injectable } from '@angular/core';

import { TranslocoService } from '@jsverse/transloco';
import { IL10nsStrings, TimeagoIntl } from 'ngx-timeago';
import { strings as stringsEN } from 'ngx-timeago/language-strings/en';
import { strings as stringsFR } from 'ngx-timeago/language-strings/fr';
import { strings as stringsNL } from 'ngx-timeago/language-strings/nl';
import { ReplaySubject, distinctUntilChanged } from 'rxjs';

import { Languages } from '../models/language.model';


@Injectable({ providedIn: 'root' })
export class LanguageService {

  readonly language$ = new ReplaySubject<Languages>(1);

  private strings = {
    nl: stringsNL,
    en: stringsEN,
    fr: stringsFR,
  } as { [key: string]: IL10nsStrings };


  constructor(
    private timeago: TimeagoIntl,
    private translocoService: TranslocoService
  ) {
    this.language$.pipe(distinctUntilChanged()).subscribe(language => {
      this.doChange(language);
    });
  }

  changeLanguage(language: Languages): void {
    this.language$.next(language);
    console.log(`{language} changeLanguage(): ${language}`);
  }

  getLanguages(): Languages[] {
    const availableLangs = this.translocoService.getAvailableLangs();

    return availableLangs.map(lang => (typeof lang === 'string' ? lang : lang.id) as Languages);
  }

  private doChange(language: Languages): void {
    this.translocoService.setActiveLang(language);

    this.timeago.strings = this.strings[language];
    this.timeago.changes.next();
  }
}
