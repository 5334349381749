import { Injectable } from '@angular/core';

import * as amplitude from '@amplitude/analytics-browser';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  setUserId(userId: string): void {
    amplitude.setUserId(userId);
  }

  setUserProperties(properties: Record<string, any>): void {
    const identify = new amplitude.Identify();
    Object.entries(properties).forEach(([ key, value ]) => {
      identify.set(key, value);
    });

    amplitude.identify(identify);
  }
}
