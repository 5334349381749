import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, filter } from 'rxjs';

import { ENVIRONMENT } from '../environments/environment.token';

export interface Breadcrumb {
  icon: string;
  title: string;
  path: string;
}

@Injectable({ providedIn: 'root' })
export class PageService {
  public appName!: string;

  contractName$ = new BehaviorSubject<string | undefined>(undefined);
  currentPath$ = new BehaviorSubject<string>('');
  breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  expertBreadcrumb$ = new BehaviorSubject<string[]>([]);

  private id?: string;

  private accountantMap: { [key: string]: Breadcrumb } = {
    contracts: {
      icon: 'home',
      title: 'Contracts',
      path: this.id ? `/contracts/${this.id}` : '/contracts',
    },
    new: {
      icon: 'file-text',
      title: 'New Contract',
      path: '/contracts/new',
    },
    settings: {
      icon: 'settings',
      title: 'Settings',
      path: '/settings',
    },
    plans: {
      icon: 'settings',
      title: 'Plans',
      path: '/settings/plans',
    },
    customers: {
      icon: 'users',
      title: 'Customers',
      path: '/customers',
    },
  };

  private router = inject(Router);
  private environment = inject(ENVIRONMENT);

  constructor() {
    this.updateAppName();

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const navigationState = this.router.getCurrentNavigation()?.extras.state;
      if (navigationState?.['contractName'] && navigationState?.['id']) {
        this.contractName$.next(navigationState['contractName']);
        this.id = navigationState['id'];
      }

      this.currentPath$.next(this.router.url);

      if (this.currentPath$.getValue().includes('#')) {
        this.currentPath$.next(this.currentPath$.getValue().split('#')[0]);
      }
      this.updateCurrentPath();
      this.id = undefined;
    });
  }

  private updateAppName(): void {
    this.appName = this.environment.appName;
  }

  private updateCurrentPath(): void {
    const contractName = this.contractName$.getValue();

    if (this.appName === 'accountant') {
      this.breadcrumbs$.next(this.setupBreadcrumb(this.currentPath$.getValue(), contractName));
    }

    this.setupExpertBreadcrumb();

  }

  private setupBreadcrumb(params: string, contractName?: string): Breadcrumb[] {
    const urlSegments = params.split('/').filter((segment) => segment);
    const breadcrumbs: Breadcrumb[] = [];

    urlSegments.forEach((segment, index) => {
      const breadcrumb = this.accountantMap[segment];

      if (!breadcrumb) {
        return;
      }

      breadcrumbs.push({
        title: breadcrumb.title,
        icon: index !== 0 ? 'chevron-right' : breadcrumb.icon,
        path: breadcrumb.path,
      });

      if (contractName && this.id) {
        breadcrumbs.push({
          icon: 'chevron-right',
          title: contractName,
          path: `/contracts/${urlSegments[1]}`,
        });
      }
    });

    return breadcrumbs;
  }

  private setupExpertBreadcrumb(): void {
    const url = this.router.url;
    const breadcrumbs = url.split('/').filter(path => path);
    this.currentPath$.next(url);
    this.expertBreadcrumb$.next(breadcrumbs);
    console.log(`{page} updateCurrentPath(): ${url}`);
  }
}
